img.milei-afuera {
  width: 100%;
  height: auto;
  max-height: 100vh;
  position: absolute;
  bottom: 0;
}

body {
  background-color: #121212;
}

div.btn-container {
  padding-top: 32px;
  text-align: center;
}

button {
  padding: 12px;
  font-size: 14px;
  font-weight: bold;
}

div.ministerio {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 48px;
  padding-top: 32px;
}